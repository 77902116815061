import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import FormInput from '@/components/form/FormInput';
import { FormReactSelect } from '@/components/form/FormReactSelect';
import { DrawerForm } from '@/components/ui/Drawer/DrawerForm';
import { DrawerFormSection } from '@/components/ui/Drawer/DrawerFormSection';
import { useTenantsQuery } from '@/features/tenants';

import { useCreateUserMutation } from '../../api';
import { ROLES, UserForm } from '../../types/UserForm';

type Props = {
  onClose: () => void;
};

const INITIAL_VALUE: UserForm = {
  email: '',
  name: '',
  tenant: null,
  role: null,
};

const scheme = yup.object({
  email: yup.string().email('Podaj poprawny adres email').required('Pole wymagane'),
  name: yup.string().required('Pole wymagane'),
  tenant: yup.object().nullable().required('Pole wymagane'),
  role: yup.object().nullable().required('Pole wymagane'),
});

export const CreateUserForm = ({ onClose }: Props) => {
  const { data: tenants, isLoading: isTenantLoading } = useTenantsQuery();
  const [createUser] = useCreateUserMutation();

  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    control,
  } = useForm<UserForm>({
    defaultValues: INITIAL_VALUE,
    resolver: yupResolver(scheme),
  });

  const handleFormSubmit = async (values: UserForm) => {
    try {
      const body = {
        name: values.name,
        email: values.email,
        tenantId: values.tenant?.id as string,
        role: values.role?.id as string,
      };
      await createUser({
        body,
      }).unwrap();
      toast.done(`Utworzono użytkownika ${body.name}`);
      onClose();
    } catch (err: any) {
      toast.error(err.data.message);
    }
  };

  return (
    <DrawerForm
      formId="form-create-user"
      onClose={onClose}
      onSubmit={handleSubmit(handleFormSubmit)}
      isSubmitting={isSubmitting}
      submitLabel="Utwórz"
    >
      <DrawerFormSection title={'Dane użytkownika'}>
        <FormInput {...register('email')} error={errors.email} placeholder={'Email'} />
        <FormInput {...register('name')} error={errors.name} placeholder={'Imię i nazwisko'} />
      </DrawerFormSection>
      <DrawerFormSection title={'Przypisana domena'}>
        <FormReactSelect
          control={control}
          placeholder={'Wybierz domene'}
          options={tenants || []}
          isLoading={isTenantLoading}
          name={'tenant'}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.id}
        />
      </DrawerFormSection>
      <DrawerFormSection title={'Rola'}>
        <FormReactSelect
          control={control}
          placeholder={'Wybierz rolę'}
          options={ROLES}
          name={'role'}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.id}
        />
      </DrawerFormSection>
    </DrawerForm>
  );
};
