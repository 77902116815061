import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import FormInput from '@/components/form/FormInput';
import { FormReactSelect } from '@/components/form/FormReactSelect';
import { DrawerForm } from '@/components/ui/Drawer/DrawerForm';
import { DrawerFormSection } from '@/components/ui/Drawer/DrawerFormSection';
import { Domain, useDomainsQuery } from '@/features/domains';

import { useTenantByIdQuery, useUpdateTenantMutation } from '../../api/tenantsApi';

type Props = {
  onClose: () => void;
  tenantId: string;
};

type Form = {
  name: string;
  relatedDomains: Array<Domain>;
};

const initialValues: Form = {
  relatedDomains: [],
  name: '',
};

export const EditDomainForm = ({ onClose, tenantId }: Props) => {
  const { data: domains, isLoading } = useDomainsQuery();
  const { data: tenant, isLoading: isLoadingManagementQuery } = useTenantByIdQuery({
    tenantId,
  });
  const [updateTenant] = useUpdateTenantMutation();

  const formData = useMemo((): Form => {
    return Object.assign({}, initialValues, tenant);
  }, [tenant]);

  const handleSubmitForm = async (values: Form) => {
    try {
      const body = {
        name: values.name,
        relatedDomains: values.relatedDomains.map((d) => d.domain),
      };
      await updateTenant({ tenantId, body }).unwrap();
      toast.success(`Pomyślnie zaktualizowano domenę ${values.name}`);
      onClose();
    } catch (err: any) {
      toast.error(err.data?.message || 'Wystąpił błąd podczas aktualizacji domeny');
    }
  };

  const {
    register,
    formState: { isSubmitting },
    control,
    handleSubmit,
    reset,
  } = useForm<Form>({
    defaultValues: formData,
  });

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  return (
    <DrawerForm
      formId="edit-domain-form"
      onClose={onClose}
      isLoading={isLoading || isLoadingManagementQuery}
      isSubmitting={isSubmitting}
      submitLabel="Zapisz"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <DrawerFormSection title={'Domena'}>
        <FormInput {...register('name')} placeholder={'Nazwa instancji'} />
      </DrawerFormSection>
      <DrawerFormSection title={'Przypisane placówki'}>
        <FormReactSelect
          control={control}
          name="relatedDomains"
          options={domains || []}
          isLoading={isLoading}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.domain}
          placeholder={'Wybierz placówki...'}
          isMulti
        />
      </DrawerFormSection>
    </DrawerForm>
  );
};
