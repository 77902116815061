import { Tenant } from '@/features/tenants';

type Role = {
  name: string;
  id: string;
};

export const ROLES = [
  {
    name: 'Admin',
    id: 'admin',
  },
  {
    name: 'Uzytkownik',
    id: 'user',
  },
  {
    name: 'Dietetyk',
    id: 'nutritionist',
  },
];

export type UserForm = {
  email: string;
  name: string;
  tenant: Tenant | null;
  role: Role | null;
};
