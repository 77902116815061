import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import FormInput from '@/components/form/FormInput';
import { FormReactSelect } from '@/components/form/FormReactSelect';
import { DrawerForm } from '@/components/ui/Drawer/DrawerForm';
import { DrawerFormSection } from '@/components/ui/Drawer/DrawerFormSection';
import { useTenantsQuery } from '@/features/tenants';

import { useUpdateUserMutation, useUserQuery } from '../../api';
import { ROLES, UserForm } from '../../types/UserForm';

type Props = {
  userId: string;
  onClose: () => void;
};

const initialValues: UserForm = {
  email: '',
  name: '',
  tenant: null,
  role: null,
};

export const EditUserForm = ({ userId, onClose }: Props) => {
  const { data: user, isLoading: isLoadingUser } = useUserQuery({ userId });
  const { data: tenants, isLoading: isLoadingTenants } = useTenantsQuery();
  const [updateUser] = useUpdateUserMutation();

  const formData: UserForm = useMemo(() => {
    return Object.assign({}, initialValues, user, {
      tenant: tenants?.find((t) => t.id === user?.tenantId),
      role: ROLES.find((role) => role.id === user?.role),
    });
  }, [tenants, user]);

  const {
    register,
    reset,
    formState: { isSubmitting },
    handleSubmit,
    control,
  } = useForm<UserForm>({
    defaultValues: formData,
  });

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  const handleFormSubmit = async (values: UserForm) => {
    try {
      console.log(values);
      const body = {
        name: values.name,
        tenantId: values.tenant?.id as string,
        email: values.email,
        role: values.role?.id as string,
      };
      await updateUser({
        userId,
        body,
      }).unwrap();
      toast.done(`Zaktualizowano dane użytkownika ${body.name}`);
      onClose();
    } catch (err: any) {
      toast.error(err.data.message);
    }
  };

  return (
    <DrawerForm
      formId="form-edit-user"
      onClose={onClose}
      isSubmitting={isSubmitting}
      submitLabel="Zapisz"
      onSubmit={handleSubmit(handleFormSubmit)}
      isLoading={isLoadingUser}
    >
      <DrawerFormSection title={'Dane użytkownika'}>
        <FormInput {...register('email')} placeholder={'Email'} />
        <FormInput {...register('name')} placeholder={'Imię i nazwisko'} />
      </DrawerFormSection>
      <DrawerFormSection title={'Przypisana domena'}>
        <FormReactSelect
          control={control}
          placeholder={'Wybierz domene'}
          options={tenants || []}
          isLoading={isLoadingTenants}
          name={'tenant'}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.id}
        />
      </DrawerFormSection>
      <DrawerFormSection title={'Rola'}>
        <FormReactSelect
          control={control}
          placeholder={'Wybierz rolę'}
          options={ROLES}
          name={'role'}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.id}
        />
      </DrawerFormSection>
    </DrawerForm>
  );
};
